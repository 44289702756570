


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Program, ReceiptKey, Tariff, TariffKey, TariffTotals } from '@/models/type'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Tooltip
  }
})
export default class CommissionsView extends Vue {
  tariffs: [TariffKey, string][] = [
    ['latest', 'Aktuelle Provisionen'],
    ['qrBillingCustomer', 'QR Rechnungskunde'],
    ['taxi', 'Taxi'],
    ['qrCodeA', 'QR Code A'],
    ['qrCodeC', 'QR Code C'],
    ['couponCash', 'EE Barzahlungscoupon eingelöst'],
    ['prepaid', 'Prepaid eingelöst'],
    ['washClub', 'Wash-Club eingelöst'],
    ['coupon', 'Coupon eingelöst']
    // ['groupon', 'Groupon eingelöst']
  ]

  eeTariffs: [TariffKey, string, string?][] = [
    // ['couponCash', 'EE Barzahlungscoupon'],
    ['ee_pp', 'EE Prepaid'],
    ['happyHour', 'EE Happy Hour / 50% Aktion'],
    ['speedyPay', 'EE Quick Pay'],
    ['specialOffer', 'EE Sonderaktion'],
    ['ee_wc', 'EE Wash-Club'],
    ['ee_wash_package_2x', 'EE Waschpaket (2x)'],
    ['ee_wash_package_3x', 'EE Waschpaket (3x)'],
    ['ee_wash_package_4x', 'EE Waschpaket (4x)'],
    ['ee_wash_package_6x', 'EE Waschpaket (6x)'],
    ['ee_wash_package_12x', 'EE Waschpaket (12x)'],
    ['ee_sub_1', 'EE ABO Einsteiger'],
    ['ee_sub_2', 'EE ABO Standard'],
    ['ee_sub_3', 'EE ABO Premium'],
    ['ee_sub_4', 'EE ABO Experte'],
    ['ee_coupon_0', 'EE Couponwäsche (Gratis) eingelöst'],
    ['ee_coupon_1', 'EE Couponwäsche (-1€) eingelöst'],
    ['ee_coupon_2', 'EE Couponwäsche (-2€) eingelöst'],
    ['ee_coupon_3', 'EE Couponwäsche (-3€) eingelöst'],
    ['ee_coupon_4', 'EE Couponwäsche (-4€) eingelöst'],
    ['ee_coupon_5', 'EE Couponwäsche (-5€) eingelöst']
  ]

  receipts: ReceiptKey[] = [
    'receiptsDKV'
  ]

  tariffsWithRelativeCommissions: TariffKey[] = []

  tariffsWithAveragePrice: TariffKey[] = [
    'qrBillingCustomer',
    'coupon'
  ]

  created (): void {
    this.calculateOutputs()
    this.calculateTariffTotals()
    this.calculateTotals()
    this.checkData()
  }

  calculateOutputs (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    const originPrograms: Program[] = this.$store.state.dataResponse.programs
    programs.forEach((program, index) => {
      const originProgram = originPrograms[index]
      Object.keys(program).forEach((key) => {
        const tariffKey = key as TariffKey
        const tariff = program[tariffKey] as Tariff
        if (tariff) {
          if (tariffKey === 'latest' && program.totalAmountCashPrograms != null) {
            const price = originProgram.latest?.price ?? null
            this.calculateProgramOutput(tariffKey, program.totalAmountCashPrograms, price, program, originProgram)
          } else {
            this.tariffs.forEach((t) => {
              if (tariffKey === t[0]) {
                const originTariff = originProgram[tariffKey]
                const amount = originTariff?.amount ? originTariff.amount : 0

                let price = originTariff?.price ?? null

                const isRelative = originTariff?.commissionsRelative && originTariff.commissionsRelative === true

                if (tariffKey === 'washClub') {
                  if (isRelative) {
                    price = program.ee_washClubRevenues?.outputPrice ?? null
                  } else {
                    price = originProgram.latest?.price ?? null
                  }
                }

                this.calculateProgramOutput(tariffKey, amount, price, program, originProgram)
              }
            })
            this.eeTariffs.forEach((t) => {
              if (tariffKey === t[0]) {
                const originTariff = originProgram[tariffKey]
                const amount = originTariff?.amount ? originTariff.amount : 0

                let price = originTariff?.price ?? null

                const isRelative = originTariff?.commissionsRelative && originTariff.commissionsRelative === true

                if (tariffKey === 'ee_coupon_0') {
                  price = originProgram.latest?.price ?? null
                } else if (tariffKey === 'ee_wc') {
                  if (isRelative) {
                    price = program.ee_washClubRevenues?.outputPrice ?? null
                  } else {
                    price = originProgram.latest?.price ?? null
                  }
                } else if (tariffKey === 'ee_sub_2') {
                  price = (originTariff?.price ? originTariff.price / 2 : null) ?? null
                } else if (tariffKey === 'ee_sub_3') {
                  price = (originTariff?.price ? originTariff.price / 4 : null) ?? null
                } else if (tariffKey.startsWith('ee_wash_package')) {
                  if (isRelative) {
                    price = originTariff?.price ?? null
                  } else {
                    price = originProgram.latest?.price ?? null
                  }
                }

                this.calculateProgramOutput(tariffKey, amount, price, program, originProgram)
              }
            })
          }
        }
      })
    })
  }

  calculateProgramOutput (key: TariffKey, amount: number, price: number | null, program: Program, originProgram: Program): void {
    const tariff = program[key]
    const originTariff = originProgram[key]
    if (tariff) {
      if (originTariff) {
        // if relative or absolute commissions are available
        if (originTariff.commissions != null) {
          // if commissions are relative
          if (originTariff.commissionsRelative && originTariff.commissionsRelative === true) {
            // if commissions must be calculated out of price
            if ((originTariff.outputCommissions == null || originTariff.outputCommissions === 0) && price != null) {
              const outputCommissionsUnit = (originTariff.commissions / 100) * (price / 1.19)
              if (outputCommissionsUnit != null) {
                if (key === 'ee_wc') {
                  tariff.outputCommissions = outputCommissionsUnit
                } else {
                  tariff.outputCommissionsUnit = outputCommissionsUnit
                  tariff.outputCommissions = outputCommissionsUnit * amount
                }
              }
            // if commissions are already given
            } else if (originTariff.outputCommissions != null) {
              const outputCommissionsUnit = amount > 0
                ? originTariff.outputCommissions / amount
                : 0
              if (outputCommissionsUnit != null) {
                tariff.outputCommissionsUnit = outputCommissionsUnit
              }
            }
            this.tariffsWithRelativeCommissions.push(key)
          // if commissions are absolute
          } else {
            tariff.outputCommissions = originTariff.commissions * amount
          }
        }
        // if commissions are not available
      } else {
        tariff.outputCommissions = null
      }
      if (tariff.outputCommissions && tariff.outputCommissions < 0) {
        tariff.outputCommissions = -1
      }
      if (tariff.outputCommissionsUnit && tariff.outputCommissionsUnit < 0) {
        tariff.outputCommissionsUnit = -1
      }
    }
  }

  calculateTariffTotals (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    const commissions: Partial<Record<TariffKey, number>> = {}
    this.tariffs.forEach((t) => {
      let tariffTotal = 0
      programs.forEach((program) => {
        const tariff = program[t[0]]
        if (tariff?.outputCommissions != null && tariff.outputCommissions >= 0) {
          tariffTotal += tariff.outputCommissions
        }
      })
      commissions[t[0]] = tariffTotal
    })
    this.eeTariffs.forEach((t) => {
      let tariffTotal = 0
      programs.forEach((program) => {
        const tariff = program[t[0]]
        if (tariff?.outputCommissions != null && tariff.outputCommissions >= 0) {
          tariffTotal += tariff.outputCommissions
        }
      })
      commissions[t[0]] = tariffTotal
    })
    if (this.$store.state.dataRequest.tariffTotals !== undefined) {
      this.$store.state.dataRequest.tariffTotals.commissions = commissions
    } else {
      this.$store.state.dataRequest.tariffTotals = { commissions }
    }
  }

  calculateTotals (): void {
    const tariffTotals: TariffTotals = this.$store.state.dataRequest.tariffTotals
    let commissionsNet = 0
    Object.keys(tariffTotals.commissions).forEach((key) => {
      const tariffKey = key as TariffKey
      commissionsNet += tariffTotals.commissions[tariffKey]
    })
    const vat = commissionsNet * 0.19
    const commissionsGross = commissionsNet + vat

    const depositGross =
      this.$store.state.dataRequest.receiptsSum +
      this.$store.state.dataRequest.receiptsEC

    const depositGrossSAP =
      this.$store.state.dataRequest.receiptsSum +
      this.$store.state.dataRequest.receiptsEC +
      this.$store.state.dataRequest.receiptsDKV

    const depositNet = depositGross - commissionsGross
    const depositNetSAP = depositGrossSAP - commissionsGross

    this.$store.state.dataRequest.commissionsNet = commissionsNet
    this.$store.state.dataRequest.commissionsGross = commissionsGross
    this.$store.state.dataRequest.vat = vat
    this.$store.state.dataRequest.depositNet = depositNet
    this.$store.state.dataRequest.depositNetSAP = depositNetSAP
    this.$store.state.dataRequest.depositGross = depositGross
    this.$store.state.dataRequest.depositGrossSAP = depositGrossSAP
  }

  toggleIdleError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie die eingegebenen Werte für die Anzahl der Leerschaltungen. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 4, reason: 'errorIdle' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 4, reason: 'errorIdle' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  // toggleReceiptsError (error: boolean): void {
  //   if (error) {
  //     const alert = {
  //       type: 'error',
  //       message: ['Bitte prüfen Sie die eingegebenen Werte für die Höhe der Tageseinnahmen. Bei Problemen wenden Sie sich an den Support.'],
  //       button: 'Support kontaktieren',
  //       disableClosing: true,
  //       src: { step: 4, reason: 'errorReceipts' }
  //     }
  //     this.$store.dispatch('createAlert', alert)
  //   } else {
  //     const src = { step: 4, reason: 'errorReceipts' }
  //     this.$store.dispatch('closeAlert', src)
  //   }
  // }

  checkData (): void {
    let idleError = false

    this.$store.state.dataRequest.programs.forEach((program: Program) => {
      Object.keys(program).forEach((key) => {
        const tariff = program[key as TariffKey] as Tariff
        if (tariff && ((tariff.outputCommissions && tariff.outputCommissions < 0) || (tariff.outputCommissionsUnit && tariff.outputCommissionsUnit < 0))) {
          idleError = true
        }
      })
    })
    this.toggleIdleError(idleError)

    // const receiptsError = this.$store.getters.dataRequest.depositNet <= 0 || this.$store.getters.dataRequest.depositGross <= 0
    // this.toggleReceiptsError(receiptsError)

    // this.$store.dispatch('toggleError', { step: 'commissions', value: idleError || receiptsError })
    this.$store.dispatch('toggleError', { step: 'commissions', value: idleError })

    this.$emit('dataCheck', true)
  }
}
