


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import NumberInput from '@/components/NumberInput/NumberInput.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Data, Program, Tariff, TariffKey } from '@/models/type'

@Component({
  components: {
    Tooltip,
    NumberInput
  }
})
export default class CounterView extends Vue {
  @Prop({ default: false }) nextStepWarning!: boolean
  @Prop({ default: 0 }) private scrollPosition!: number

  counters: [string, string][] = [
    ['counterEndOfDay', 'Ende - Tagesende'],
    ['counterStartOfDay', 'Start - Tagesbeginn'],
    ['difference', 'Differenz']
  ]

  tariffs: [TariffKey, string, string?][] = [
    ['qrBillingCustomer', 'QR Rechnungskunde'],
    ['taxi', 'Taxiwäschen'],
    ['qrCodeA', 'QR Code A', '(Rabattpreise)'],
    ['qrCodeC', 'QR Code C', '(Aktionspreise)'],
    ['couponCash', 'EE Barzahlungscoupon eingelöst'], // verschoben
    ['prepaid', 'Prepaidwäschen eingelöst'],
    ['washClub', 'Wash-Club Wäschen eingelöst'],
    ['coupon', 'Couponwäschen eingelöst']
    // ['groupon', 'Grouponwäschen eingelöst']
  ]

  eeTariffs: [TariffKey, string, string?][] = [
    // ['couponCash', 'EE Barzahlungscoupon eingelöst'],
    ['ee_pp', 'EE Prepaid eingelöst'],
    ['happyHour', 'EE Happy Hour / 50% Aktion eingelöst'],
    ['speedyPay', 'EE Quick Pay eingelöst'],
    ['specialOffer', 'EE Sonderaktion EE eingelöst'],
    ['ee_wc', 'EE Wash-Club eingelöst'],
    ['ee_wash_package_2x', 'EE Waschpaket (2x) eingelöst'],
    ['ee_wash_package_3x', 'EE Waschpaket (3x) eingelöst'],
    ['ee_wash_package_4x', 'EE Waschpaket (4x) eingelöst'],
    ['ee_wash_package_6x', 'EE Waschpaket (6x) eingelöst'],
    ['ee_wash_package_12x', 'EE Waschpaket (12x) eingelöst'],
    ['ee_sub_1', 'EE ABO Einsteiger eingelöst'],
    ['ee_sub_2', 'EE ABO Standard eingelöst'],
    ['ee_sub_3', 'EE ABO Premium eingelöst'],
    ['ee_sub_4', 'EE ABO Experte eingelöst'],
    ['ee_coupon_0', 'EE Couponwäsche (Gratis) eingelöst']
  ]

  eeCoupons: [TariffKey, number, string, string][] = [
    ['ee_coupon_1', 1, 'EE Couponwäsche (-1€) eingelöst', '(App-Guthaben)'],
    ['ee_coupon_2', 2, 'EE Couponwäsche (-2€) eingelöst', '(App-Guthaben)'],
    ['ee_coupon_3', 3, 'EE Couponwäsche (-3€) eingelöst', '(App-Guthaben)'],
    ['ee_coupon_4', 4, 'EE Couponwäsche (-4€) eingelöst', '(App-Guthaben)'],
    ['ee_coupon_5', 5, 'EE Couponwäsche (-5€) eingelöst', '(App-Guthaben)']
  ]

  private hasCounters = false

  private idleError = false

  @Watch('nextStepWarning')
  onNextStepWarning (): void {
    if (this.nextStepWarning) {

    }
  }

  created (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    programs.forEach((program) => {
      if (typeof program.counterEndOfDay === 'number') {
        this.hasCounters = true
      } else {
        this.hasCounters = false
      }
      this.calculateDifference(program)
      this.calculateTotalAmountCashPrograms(program)
    })
    this.calculateCounters()
    this.addMissingTariffs()
    this.checkData()
    let alert = {
      type: 'success',
      message: ['Die Zählerstände wurden automatisch übermittelt.'],
      src: { step: 2, reason: 'countAvailable' }
    }
    if (!this.hasCounters) {
      alert = {
        type: 'warn',
        message: ['Die Zählerstände für diesen Tag konnten nicht automatisch übermittelt werden. Bitte tragen Sie diese manuell ein.'],
        src: { step: 2, reason: 'countNotAvailable' }
      }
    }
    setTimeout(() => {
      this.$store.dispatch('createAlert', alert)
    }, 500)
  }

  calculate (program: Program): void {
    this.calculateDifference(program)
    this.calculateTotalAmountCashPrograms(program)
    this.calculateCounters()
    this.checkData()
  }

  calculateDifference (program: Program): void {
    if (typeof program.counterEndOfDay !== 'number' || typeof program.counterStartOfDay !== 'number') {
      program.difference = null
    } else {
      program.difference = program.counterEndOfDay - program.counterStartOfDay
    }
  }

  calculateTotalAmountCashPrograms (program: Program): void {
    if (typeof program.difference !== 'number' || typeof program.amountIdlePrograms !== 'number') {
      program.totalAmountCashPrograms = null
    } else {
      let totalNonCash = 0
      this.tariffs.forEach((tariff) => {
        totalNonCash += program[tariff[0]]?.amount ?? 0
      })
      this.eeTariffs.forEach((tariff) => {
        totalNonCash += program[tariff[0]]?.amount ?? 0
      })
      this.eeCoupons.forEach((tariff) => {
        totalNonCash += program[tariff[0]]?.amount ?? 0
      })

      program.totalAmountCashPrograms =
        program.difference -
        program.amountIdlePrograms -
        totalNonCash
    }
  }

  calculateCounters (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    let countersComplete = true
    let counterDaily = 0
    let counterTotal = 0
    programs.forEach((program) => {
      if (typeof program.difference !== 'number') {
        countersComplete = false
      } else {
        counterDaily += program.difference
        counterTotal += program.counterEndOfDay
      }
    })
    if (countersComplete) {
      this.$store.state.dataRequest.counterDaily = counterDaily
      this.$store.state.dataRequest.counterTotal = counterTotal
    } else {
      this.$store.state.dataRequest.counterDaily = null
      this.$store.state.dataRequest.counterTotal = null
    }
  }

  addMissingTariffs (): void {
    const programs: Program[] = this.$store.state.dataRequest.programs
    const filledDataRequest: Data = JSON.parse(JSON.stringify(this.$store.state.dataRequest))
    const filledDataResponse: Data = JSON.parse(JSON.stringify(this.$store.state.dataResponse))
    programs.forEach((program, index) => {
      const newTariff: Tariff = {
        amount: 0,
        sold: 0,
        commissions: program.ee_wc?.commissions ?? null,
        commissionsRelative: program.ee_wc?.commissionsRelative ?? false
      }
      const freeCouponTariff = program.ee_coupon_0
      if (freeCouponTariff === undefined) {
        let price: number | undefined
        if (program.latest?.price != null && program.latest.price > 0) {
          price = 0
        }
        filledDataRequest.programs[index].ee_coupon_0 = { ...newTariff, price }
        filledDataResponse.programs[index].ee_coupon_0 = { ...newTariff, price }
      }
      this.eeCoupons.forEach((coupon) => {
        const tariffKey = coupon[0]
        const tariff = program[tariffKey]
        if (tariff === undefined) {
          let price: number | undefined
          if (program.latest?.price != null && program.latest.price > 0) {
            price = coupon[1] ? program.latest?.price - coupon[1] : 0
          }
          filledDataRequest.programs[index][tariffKey] = { ...newTariff, price, amount: 0 }
          filledDataResponse.programs[index][tariffKey] = { ...newTariff, price, amount: 0 }
        }
      })
    })
    this.$store.commit('dataRequest', JSON.parse(JSON.stringify(filledDataRequest)))
    this.$store.commit('dataResponse', JSON.parse(JSON.stringify(filledDataResponse)))
  }

  updateEeAmount (index: number, key: TariffKey, value: number | null): void {
    this.$store.state.dataRequest.programs[index][key].amount = value
    this.$store.state.dataResponse.programs[index][key].amount = value
    this.calculate(this.$store.state.dataRequest.programs[index])
  }

  toggleCountError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie den eingegebenen Wert für das Tagesende. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 2, reason: 'errorCount' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 2, reason: 'errorCount' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  toggleIdleError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie die eingegebenen Werte für die Anzahl der Leerschaltungen. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 2, reason: 'errorIdle' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 2, reason: 'errorIdle' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  checkData ():void {
    let dataComplete = true
    let idleError = false
    let countError = false
    const programs: Program[] = this.$store.state.dataRequest.programs
    programs.forEach((program) => {
      if (typeof program.counterStartOfDay !== 'number' || typeof program.counterEndOfDay !== 'number') {
        dataComplete = false
      }
      if (program.difference != null && program.difference < 0) {
        countError = true
      }
      if (typeof program.amountIdlePrograms !== 'number') {
        dataComplete = false
      }
      if (program.totalAmountCashPrograms != null && program.totalAmountCashPrograms < 0) {
        idleError = true
      }
    })
    this.idleError = idleError
    this.toggleCountError(countError)
    this.toggleIdleError(this.idleError)
    this.$store.dispatch('toggleError', { step: 'counters', value: this.idleError || countError })
    this.$emit('dataCheck', dataComplete)
    // this.$emit('dataCheck', true)
    // console.log('data', this.$store.state.dataRequest)
  }
}
